$icons: (
	'detalhe-depoimento-3': 0px 0px 53px 42px,
	'megafone-agenda-1': -84px -52px 19px 18px,
	'pdf': -113px 0px 28px 30px,
	'setinha': -113px -75px 12px 10px,
	'stardust': -43px -52px 31px 31px,
	'tel': -113px -40px 25px 25px,
	'telemarketing-sm': 0px -52px 33px 34px,
	'telemarketing': -63px 0px 40px 42px,
	'tiktok': 0px -96px 18px 19px,
);

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 141px 115px;

	display: inline-block;
	vertical-align: middle;

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 141px 115px;

	display: inline-block;
	vertical-align: middle;
	background-color: currentcolor;

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			mask-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}
