.assistencia__input{
	background-color: #ededed;
	border: none;
	color: #151c48;
	resize: none;
	padding: 15px 20px 13px 20px;

	&::placeholder{
		color: #151c48;
	}

	&:focus{
		background-color: #ededed;
	}
}
.assistencia__form{
	width: 100%;
	max-width: 900px;
	margin-right: auto;
	margin-left: auto;
	text-align: left;
}
.assistencia__desc{
	margin-bottom: 40px;
}
.assistencia__interna{
	padding-bottom: 50px;
	text-align: center;
}
.assistencia-int__botao{
	text-align: center;
}
.assistencia-int__btn{
	font-family: 'montserratextrabold';
	font-size: 18px;
	background-color: transparent;
	border: 2px solid #144681;
	color: #144681;
	transition: all 0.3s linear;
	display: inline-block;
	padding: 16px 25px 14px 25px;
	border-radius: 10px;
	margin-top: 20px;

	&:hover{
		color: #fff;
		background-color: #144681;
	}
}
.titulo__secao__interna{
	color: #144681;
}