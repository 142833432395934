.contato-page#contato3{
	padding-bottom: 80px;

	.title-ordenal{
		padding-left:3em;
		position: relative;

		.order{
			display: block;
			font-size: 16px;
			color: #FFBF00;
		}

		&:before{
			content: '';
			display: block;
			width: 2em;
			height:0.2em;
			background: currentcolor;
			position: absolute;
			left: 0;
			top: calc(16px * 1.4 + calc(1em * 1.4 * 0.4));
			transform: translateY(-50%);
		}
	}

	form{
		border-top:5px solid #000;
		padding-top:40px;
		margin-bottom:60px;
	}

	.md-form{
		position: relative;

		.form-control{
			border-top:none;
			border-right:none;
			border-left:none;
			border-bottom-width:3px;
			border-color: rgba(#000,0.3);

			&::placeholder{
				color: transparent;
			}
		}

		.hover-eff{
			position: absolute;
			left:0;
			bottom:1rem;
			height:3px;
		}
	}

	.btn-enviar{
		display: inline-flex;
		align-items:center;
		border-radius:0;

		@include button-variant(#FFF, #FFF);

		&:before{
			content:'';
			width:1.5em;
			height: 0.25em;
			background: currentcolor;
			display: inline-block;
			margin-right: 7px;
		}
	}

	.dados-contato{
		a{
			color: inherit;

			&:hover{
				color: #FFBF00;
			}
		}
	}
}