@import "_modelo-1.scss";
@import "_modelo-2.scss";
@import "_modelo-3.scss";

.modal-videos{
	.close{
		font-size: 16px;
	}

	.modal-content{
		background: none;
		border:none;
		box-shadow: none;
	}

	.modal-header{
		background: transparent;
		border: none;
		padding-bottom: 0;
	}

	.embed-responsive{
		background: #000;
		padding-bottom: percentage(9/16);
	}
}