.contato-page#contato1{
	background-position: center top;


	#mapa-contato{
		padding-bottom: percentage(472/1920);
	}

	.pdt-70{
		padding-top: 70px;
	}

	.md-form{
		.form-control{
			border-top:none;
			border-right:none;
			border-left:none;
			border-bottom-width:3px;
			border-color: #000;
		}

		.hover-eff{
			position: absolute;
			left: 0;
			bottom:1rem;
			z-index: 2;
			height:3px;
		}
	}

	.btn-enviar{
		background: #000;
		color: #FFF;
		padding-left:1.5rem;
		padding-right:1.5rem;
		border-radius: 2rem;

		&:hover{
			background: lighten(#000,20%);
			color: #FFF;
		}
	}

	.media-contato{
		padding: 30px 0;
		border-bottom:2px dashed rgba(#000,0.2);
		border-top:2px dashed rgba(#000,0.2);

		.media-body{
			align-self: center;
		}

		.icon{
			margin-right: 15px;
			align-self: center;
		}
	}
}