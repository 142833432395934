#orcamento-mod-3{
	padding-bottom: 80px;

	.block-note{
		padding-left: 15px;
		border-left:5px solid #ff7e00;
		margin-bottom: 40px;

		h2{
			font-size: 18px;
		}

		p {
			color: #777;
		}
	}

	.form-control{
		height: 44px;

		&.output{
			line-height: calc(44px - 0.75rem);
			position: relative;
			padding-right: calc(1em + 1.5rem);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:before{
				content:'';
				display: block;
				border-top:0.5em solid currentcolor;
				border-right:0.5em solid transparent;
				border-left:0.5em solid transparent;
				position:absolute;
				right:0.75rem;
				top:50%;
				transform: translateY(-50%);
			}
		}
	}


	textarea.form-control{
		height:auto;
	}

	.btn-enviar{
		@include button-variant(#ff7e00, #ff7e00);
		color: #FFF;

		&:hover{
			color: #FFF;
		}
	}
}