.rodape{
	z-index: 1;
	background-color: #2b9cd8;
}
.rodape__conteudo{
	color: #fff;
	padding: 40px 0px;
}
.rodape__logo{
	display: flex;
	align-items: center;
}
.rodape__redes{
	display: flex;
	align-items: center;
	justify-content: center;
}
.rodape__sociais{
	display: flex;
	align-items: center;
	font-size: 16px;
	padding: unset;

	&::before{
		background-image: url(../images/@.png);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center;
		width: 126px;
		height: 114px;

		content: '';
		position: absolute;
		left: calc(50% - 63px);
		top: calc(50% - 57px);
		z-index: -1;
	}

	li{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 40px;
		height: 40px;

		background-color: transparent;
		border: 1px solid #FFF;
		border-radius: 50%;

		margin: 5px;
		transition: all 0.3s linear;

		.rodape__sociais__item{
			color: #FFF;
			font-size: 20px;
		}

		&:hover{
			background-color: #FFF;

			.rodape__sociais__item{
				color: #2b9cd8;
				text-decoration: none;
			}
		}
	}
}
.rodape__newsletter{
	.titulo__newsletter{
		font-family: 'montserratbold';
		font-size: 18px;
		color: #FFF;
		line-height: 1.2;

		margin-bottom: 8px;
	}

	.desc__newsletter{
		font-family: 'montserratregular';
		font-size: 14px;
		color: #FFF;
		line-height: 1.2;

		margin-bottom: 8px;
	}

	.newsletter__form{

		.newsletter__input{
			background-color: rgba(255, 255, 255, 0.4);
			border: none;
			border-radius: 15px;
			border: 1px solid #FFF;
			padding: 15px 20px;
			height: auto;
			font-size: 15px;
			color: #FFF !important;

			&::placeholder {
				color: #FFF !important;
			}
		}

		.form-group{
			position: relative;

			.enviar__botao{
				position: absolute;
				top: calc(50% - 20px);
				right: 10px;

				display: flex;
				align-items: center;
				justify-content: center;

				width: 40px;
				height: 40px;

				background-color: transparent;
				font-size: 20px;
				color: #FFF;
				border: none;
			}
		}
	}
}
.rodape__creditos{
	color: #FFF;

	.container__creditos{
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 12px 0px;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			padding: 16px 10px;
		}

		.creditos {
			color: #FFF;
			font-size: 12px;
			font-family: 'montserratregular';
			text-align: center;

			span{
				text-transform: uppercase;
			}

			svg {
				margin-left: 5px;
				color: #FFF;
			}

			@include media-breakpoint-down(md) {
				text-align: center;
			}
		}
	}
}

// TABLET PRA BAIXO
@include media-breakpoint-down (md) {
	.gv8{
		justify-content: center;
	}
	.rodape__logo{
		justify-content: center;
		margin-bottom: 80px;
	}
	.rodape__redes{
		margin-bottom: 80px;
	}
}
// FIM DE TABLE PRA BAIXO
