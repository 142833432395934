@import "_fontfaces";


.page__header,
.page-header{
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
	position: relative;
	perspective: 3px;
	z-index: 0;

	& ~ * {
		position: relative;
		z-index: 2;
	}

	@include media-breakpoint-down(xs) {
		--font-size: 50px;
	}
}

.titulo__secao__interna:before{
	content: var(--phantom-text, '');
	font-family: 'montserratblack';
	position: absolute;
	width: 100%;
	max-width: 880px;
	height: 100%;
	z-index: -1;
	font-size: var(--font-size, 120px);
	left: 50%;
	top: 10px;
	transform: translateX(-50%);
	color: #FFF;
	--shadow: #ebebeb;
	text-align: center;

	text-shadow: -1px 0 var(--shadow), 
	-1px -1px var(--shadow), 
	0 -1px var(--shadow), 
	1px -1px var(--shadow), 
	1px 0px var(--shadow), 
	1px 1px var(--shadow), 
	0 1px var(--shadow), 
	-1px 1px var(--shadow);
}

// .page__header canvas{
// 	position: absolute;
// 	left: 50%;
// 	top: 50%;
// 	transform: translate(-50%, -50%);
// 	z-index: -1;
// 	max-width: 100%;
// }