.titulo__secao{
	font-family: 'montserratregular';
	font-size: 18px;
	margin-bottom: 20px;
}
.sub__secao{
	font-family: 'montserratbold';
	font-size: 48px;
}
.btn__conteudo{
	font-family: 'montserratmedium';
	font-size: 15px;
	background-color: #2b9cd8;
	color: #fff;
	border-radius: 10px;
	padding: 12px 20px;
	display: inline-block;
	transition: all 0.3s linear;

	&:hover{
		text-decoration: none;
	}
}
.grid__produto__home{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 15px;
	row-gap: 15px;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-down (xs) {
		grid-template-columns: repeat(1, 1fr);
	}
}
.banner{
	width: 100%;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;
	position: relative;
}
.banner__inner{
	max-width: 1920px;
	width: 160%;
	left: 50%;
	transform: translateX(-50%);
}
.banner__controles{
	font-size: 28px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	color: #fff;
	bottom: 0;
	display: flex;
	z-index: 10;
}
.banner__prev,
.banner__next{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 30px;
	height: 30px;
	border-radius: 50%;

	background-color: #FFF;
	color: #afafaf;
	font-size: 16px;

	&:hover{
		background-color: #2b9cd8;
		text-decoration: none;
		transition: all 0.5s linear;
	}
}
.banner__prev{
	margin-right: 10px;
}
.quem-somos{
	background-color: #FFF;
}
.quemsomos__txt{
	width: 100%;
	max-width: 650px;
	margin-left: auto;
	margin-right: 0;
	padding-left: 110px;
	padding-top: 30px;
	background-image: url('../images/quemsomos-bg-frase.png');
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;
}
.quemsomos__sub{
	margin-bottom: 30px;

	span{
		color: #2b9cd8;
	}
}
.quemsomos__desc{
	font-family: 'montserratmedium';
	margin-bottom: 40px;
}
.servicos__txt{
	color: #000;
	margin-bottom: 15px;
}
.servicos__card{
	display: block;
	padding: 20px 0px;
	margin-bottom: 10px;

	&:hover{
		text-decoration: none;

		.servicos__img{
			background-color: #fff;
		}
		.servicos__moldura{
			background-color: #2b9cd8;

			&:before{
				opacity: 1;
			}
		}

		.servicos__nome{
			color: #2b9cd8;
		}
	}
}
.servicos__img{
	background-color: #151c48;
	width: 60px;
	height: 60px;
	-webkit-mask-position: center center;
	-webkit-mask-size: auto;
	mask-position: center center;
	mask-size: auto;
	mask-repeat: no-repeat;
	mask-image: var(--icon);
	-webkit-mask-image: var(--icon);
	transition: all 0.3s linear;
}
.servicos__moldura{
	width: 86px;
	height: 86px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: auto;
	margin-left: auto;
	background-color: #ededed;
	border-radius: 50%;
	margin-bottom: 30px;
	transition: all 0.3s linear;
	position: relative;

	&:before{
		width: 108px;
		height: 108px;
		position: absolute;
		top: -11px;
		left: -11px;
		border: 2px solid #2b9cd8;
		content: '';
		border-radius: 50%;
		opacity: 0;
		transition: all 0.3s linear;
	}
}
.servicos__nome{
	font-family: 'montserratblack';
	font-size: 16px;
	margin-bottom: 15px;
	line-height: 18px;
	height: 36px;
	padding: 0px 20px;
}
.servicos__desc{
	--linhas: 4;
	color: #777777;
}
.servicos__owl{
	display: flex !important;
	flex-direction: column;

	.owl-nav{
		order: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ededed;

		position: absolute;
		left: 0;
		right: 0;
		bottom: -35px;

		.owl-prev{
			margin-right: 130px;
		}
		.owl-next{
			margin-left: 130px;
		}
		.owl-prev,
		.owl-next{
			display: flex;
			justify-content: center;
			align-items: center;

			background-color: #ededed;

			width: 25px;
			height: 25px;
			font-size: 15px;
			border-radius: 50%;

			color: #FFF;

			&:hover{
				background-color: #03417d;
				color: #FFF;

				transition: all 0.5s linear;
			}
		}
	}
	.owl-stage-outer{
		order: 2;
	}
	.owl-dots{
		order: 3;
	}
}
.btn__servicos{
	background-color: transparent;
	border: 2px solid #03417d;
	color: #03417d;
}
.produtos{
	padding: 90px 0px;
	color: #fff;

	background-image: url('../images/background-produtos.png');
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;
	min-height: 896px;

	&.prod__2{
		background-image: unset;
		min-height: unset;
		color: #151c48;
	}

	.btn__center{
		display: flex;
		justify-content: center;
		padding-top: 30px;

		a{
			background-color: #fff;
			color: #03417d;

			&:hover{
				background-color: #151c48;
				color: #fff;
			}
		}
	}
}
.produtos__2{
	padding: 90px 0px;
	color: #fff;
	background-image: url('../images/background-produtos.png');
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;
}
.sub__secao__prod{
	margin-bottom: 40px;
	text-align: center;
}

.segura__categorias{
	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 20px;

	.cat{
		font-family: 'montserratmedium';
		font-size: 15px;
		background-color: #2b9cd8;
		color: #fff;
		border-radius: 10px;
		border: 1px solid transparent;
		padding: 12px 20px;
		display: inline-block;
		transition: all 0.3s linear;

		margin: 0px 4px;

		&.active{
			background-color: #03417d;
			border-color: #FFF;
			color: #FFF;
		}

		&:hover{
			background-color: #03417d;
			text-decoration: none;
			border-color: #FFF;
			color: #FFF;

			transition: all 0.5s linear;
		}
	}
}

.produtos__owl{

	.owl-nav{
		order: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ededed;

		position: absolute;
		left: 0;
		right: 0;
		bottom: -35px;

		.owl-prev{
			margin-right: 20px;
		}
		.owl-next{
			margin-left: 20px;
		}
		.owl-prev,
		.owl-next{
			display: flex;
			justify-content: center;
			align-items: center;

			background-color: #ededed;

			width: 25px;
			height: 25px;
			font-size: 15px;
			border-radius: 50%;

			color: #FFF;

			&:hover{
				background-color: #03417d;
				color: #FFF;

				transition: all 0.5s linear;
			}
		}
	}
	.owl-stage-outer{
		order: 2;
	}
	.owl-dots{
		order: 3;
	}
}
.produtos__card{
	display: flex;
	align-items: center;
	flex-direction: column;

	width: 100%;
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;

	padding: 20px 15px;

	background-color: #ededed;
	border-radius: 20px;

	.segura__imagem{
		margin-bottom: 20px;
	}

	.produtos__nome{
		font-family: 'montserratblack';
		font-size: 18px;
		color: #777777;
		line-height: 1.2;

		margin-bottom: 10px;
	}

	.produtos__desc{
		--linhas: 4;
		color: #777777;
		max-width: 260px;
	}

	&:hover{
		text-decoration: none;

		.servicos__img{
			background-color: #fff;
		}
		.servicos__moldura{
			background-color: #2b9cd8;

			&:before{
				opacity: 1;
			}
		}

		.servicos__nome{
			color: #2b9cd8;
		}
	}
}
.categoria__card{
	margin-bottom: 10px;
}
.categoria__btn{
	background-color: transparent;
	color: #fff;
	font-family: 'montserratbold';
	font-size: 18px;
	border-radius: 10px;
	padding: 15px 40px 15px 40px;
	border: none;
	border: 1px solid #FFF;
	display: block;
	width: 100%;
	text-align: left;
	position: relative;
	transition: all 0.3s linear;

	&:focus{
		outline: none;
	}

	&[aria-expanded="true"]{
		background-color: #2b9cd8;
		border-color: #2b9cd8;

		&:after{
			content: '\f068';
			background-color: #FFF;
			color: #2b9cd8!important;
		}
	}

	&:after{
		content: '\f067';
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		position: absolute;
		font-size: 13px;
		right: 15px;
		width: 30px;
		height: 31px;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		align-items: center;
		justify-content: center;
		color: #03417d!important;
		z-index: 2;
		border-radius: 50%;
		background-color: #FFF;
	}
}
.categoria__lista{
	padding: 40px 16px;
	border-bottom: 1px solid #ededed;
	border-left: 1px solid #ededed;
	border-right: 1px solid #ededed;
	background-color: transparent;
	margin-bottom: 0;
	list-style: none;
	font-family: 'montserratmedium';
	font-size: 18px;
	max-height: 280px;
	overflow-y: auto;
	overflow-x: hidden;

	margin-top: -10px;
	border-radius: 0px 0px 10px 10px;

	&::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar-track{
		background-color: #144681;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #fff;
		height: 10px;

		&:hover{
			background-color: rgba(#fff, 0.6);
		}
	}
}
.categoria__li{
	border-bottom: 1px solid rgba(#375163, 0.25);

	&:last-child{
		border-bottom: none;
	}
}
.categoria__item{
	padding: 10px 15px 7px 30px;
	display: block;
	position: relative;

	&:before{
		content: url('../images/setinha-dir.png');
		position: absolute;
		top: 10px;
		left: 0px;
		width: 11px;
		height: 12px;
		opacity: 0;
	}
}
.categoria__item--active{
	font-family: 'montserratblack';
	transform: translateX(30px);

	&:before{
		opacity: 1;
	}
}
.detalhes__img{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	&:before{
		width: 70%;
		height: 100%;
		border: 8px solid #fff;
		content: '';
		position: absolute;
		top: 0;
		right: 10px;
	}
}
.produto__desc{
	margin-bottom: 20px;
}
.produto__nome{
	font-family: 'montserratextralight';
	font-size: 48px;
}
.produto__sub{
	font-family: 'montserratbold';
	font-size: 18px;
}
.baixar__flex{
	margin-top: 40px;
	display: flex;
	justify-content: center;
}
.baixar__btn{
	font-family: 'montserratextrabold';
	font-size: 18px;
	display: flex;
	align-items: center;
	line-height: 18px;
	border: 2px solid #fff;
	border-radius: 10px;
	padding: 8px 15px;
	transition: all 0.3s linear;
	background-color: #144681;
	color: #FFF;

	&:first-child{
		margin-right: 40px;
	}

	&:hover{
		text-decoration: none;
		border: 2px solid #2b9cd8;
		background-color: #2b9cd8;
		color: #FFF;
	}
}
.pdf{
	margin-right: 10px;
}
.noticias{
	background-color: #FFF;
	text-align: center;
	color: #144681;
}
.sub__secao-noticias{
	width: 100%;
	max-width: 740px;
	color: #03417d;
	font-size: 40px;

	margin-left: auto;
	margin-right: auto;
	margin-bottom: 70px;
}
.video__dialog{
	max-width: 80%;
}
.video__content{
	background-color: transparent;
}
#modalVideo{
	background-color: rgba(#000, 0.6);
}
.modal-header,
.modal-footer{
	padding: 0;
	display: flex;
	justify-content: flex-end;
	border: none;
}
.video__fechar{
	color: #fff;
	border: none;
	background-color: transparent;
	font-size: 30px;
	transition: all 0.3s linear;

	&:focus{
		outline: none;
	}

	&:hover{
		color: rgba(#fff, 0.4);
		cursor: pointer;
	}
}
.video__thumbnail{
	position: relative;
	display: block;
	margin-top: 15px;
	margin-bottom: 15px;
	z-index: 1;
	cursor: pointer;;

	&:after{
		height: calc(100% + 30px);
		width: 50%;
		left: 50%;
		transform: translateX(-50%);
		content: '';
		position: absolute;
		top: -15px;
		border: 8px solid #144681;
		z-index: -1;
	}
}
.noticia__card{
	display: block;
	position: relative;
	width: 100%;
	max-width: 254px;
	margin-right: auto;
	margin-left: auto;

	&:hover{
		text-decoration: none;

		.noticia__data{
			background-color: #151c48;
		}
		.noticia__txt{
			&:after{
				opacity: 1;
			}
		}
		.noticia__titulo{
			color: #151c48;
		}
	}
}
.noticia__data{
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	background-color: #144681;
	color: #fff;
	padding: 8px 25px;
	font-family: 'montserratsemibold';
	font-size: 15px;
	width: 160px;
	transition: all 0.3s linear;
}
.noticia__txt{
	padding: 20px 5px;
	background-color: #fff;
	width: 90%;
	position: relative;
	margin-left: auto;
	margin-right: auto;

	&:after{
		width: 100%;
		height: 3px;
		bottom: 0;
		left: 0;
		background-color: #151c48;
		opacity: 0;
		transition: all 0.3s linear;
		content: '';
		position: absolute;
	}
}
.noticia__titulo{
	--linhas: 2;
	font-family: 'montserratbold';
	font-size: 15px;
	color: #144681;
	margin-bottom: 5px;
	transition: all 0.3s linear;
}
.noticia__desc{
	--linhas: 3;
	color: #777777;
}
.noticias__botao{
	text-align: center;
}
.btn__noticias{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 190px;
	height: 50px;

	background-color: #03417d;
	color: #FFF;

	margin-left: auto;
	margin-right: auto;


	&:hover{
		color: #fff;
	}
}
.owl__noticias{
	position: relative;
	margin-bottom: 40px;

	.owl-nav{
		position: absolute;
		bottom: -50px;
		transform: translateY(50%);
		right: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		font-size: 30px;
		color: #fff;

		.owl-prev,
		.owl-next{
			display: flex;
			justify-content: center;
			align-items: center;

			background-color: #ededed;

			width: 25px;
			height: 25px;
			font-size: 15px;
			border-radius: 50%;

			color: #FFF;

			&:hover{
				background-color: #03417d;
				color: #FFF;

				transition: all 0.5s linear;
			}
		}
		.owl-prev{
			margin-right: 5px;
		}
		.owl-next{
			margin-left: 5px;
		}
	}
}
.assistencia{
	background-color: #03417d;
	padding: 60px 0px;
}
.assistencia__conteudo{
	display: flex;
	align-items: center;
	flex-direction: column;

	h1{
		font-family: 'montserratbold';
		font-size: 50px;
		color: #fff;
		line-height: 1.3em;

		margin-bottom: 40px;

		span{
			color: #2b9cd8;
		}
	}

	@include media-breakpoint-down(sm){
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		.btn__assistencia{
			margin-top: 20px;
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}
}
.btn__assistencia{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 300px;
	height: 50px;

	font-family: 'montserratextrabold';
	font-size: 18px;
	color: #FFF;

	border: 2px solid #fff;
	background-color: transparent;
	border-radius: 10px;

	transition: all 0.3s linear;

	&:hover{
		text-decoration: none;
		color: #FFF;
	}
}
.locacao{
	background-image: url('../images/bg-locacao.jpg');
	background-repeat: no-repeat;
	background-size: 1920px 100%;
	background-position: center top;
	color: #fff;
}
.sub__secao__locacao{
	width: 100%;
	max-width: 680px;
	margin-left: 0;
	margin-right: auto;
	margin-bottom: 40px;
}
.locacao__desc{
	width: 100%;
	max-width: 670px;
	margin-left: 0;
	margin-right: auto;
	margin-bottom: 30px;
}
.locacao__btn{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 190px;
	height: 50px;

	background-color: #03417d;
}
.sub__secao__clientes{
	color: #151c48;
}
.segura__clientes__owl{
	padding: 15px 25px;
	position: relative;
}
.owl__clientes{
	.owl-nav{
		font-size: 30px;
		color: #fff;
		text-shadow: 0px 0px 5px rgba(#000, 0.4);

		.owl-prev,
		.owl-next{
			display: flex;
			justify-content: center;
			align-items: center;

			background-color: #ededed;

			width: 25px;
			height: 25px;
			font-size: 15px;
			border-radius: 50%;

			color: #FFF;

			position: absolute;
			top: 50%;
			transform: translateX(-50%);

			&:hover{
				background-color: #03417d;
				color: #FFF;

				transition: all 0.5s linear;
			}
		}
		.owl-prev{
			left: -10px;
		}
		.owl-next{
			left: calc(100% + 10px);
		}
	}
}
.clientes__card{
	display: block;
	text-align: center;
	vertical-align: middle;
	margin-right: auto;
	margin-left: auto;

	&:hover{
		.clientes__img{
			filter: grayscale(0%);
		}
	}
}
.clientes__img{
	width: 150px;
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: auto;
	margin-left: auto;
	filter: grayscale(100%);
	transition: all 0.3s linear;
}
.sub__secao__contato{
	margin-bottom: 50px;
}
.contato__row-cima{
	margin-bottom: 40px;
}
.contato__textarea{
	border-bottom: none;
	border-radius: 10px 10px 0px 0px;
	resize: none;
}
.contato__botao{
	text-align: right;
}

.contato{

	.col__infos{
		display: flex;
		align-items: flex-start;
		flex-direction: column;

		.titulo__contato{
			font-family: 'montserratbold';
			font-size: 25px;
			color: #2b9cd8;
			line-height: 1.2;

			margin-bottom: 40px;
		}

		.box__head{
			display: flex;
			align-items: center;
			color: #2b9cd8;

			.contato__titulo{
				font-family: 'montserratbold';
				font-size: 18px;
				color: #2b9cd8;
				line-height: 1.2;

				margin-left: 8px;
			}
		}

		.contato__infos{
			display: flex;
			align-items: flex-start;
			flex-direction: column;

			margin-bottom: 20px;

			span{
				max-width: 290px;
				text-align: start;
			}
		}
	}

	.titulo__form{
		font-family: 'montserratbold';
		font-size: 25px;
		color: #2b9cd8;
		line-height: 1.2;
		text-align: left;

		margin-bottom: 40px;
	}

	.contato__form{

		.contato__input{
			background-color: #FFF;
			border: none;
			border-radius: 25px;
			padding: 10px 20px 8px 20px;
			height: auto;
			font-size: 15px;
			color: #03417d !important;

			&::placeholder {
				color: #03417d !important;
			}
		}

		.contato__textarea{
			background-color: #FFF;
			border: none;
			border-radius: 25px;
			padding: 10px 20px 8px 20px;
			height: auto;
			font-size: 15px;
			color: #03417d !important;

			&::placeholder {
				color: #03417d !important;
			}
		}

		.captcha{
			margin-top: 20px;
		}

		.contato__botao{

			.contato__btn{
				display: flex;
				align-items: center;
				justify-content: center;

				width: 160px;
				height: 50px;

				background-color: #2b9cd8;
				border: none;
				border-radius: 10px;

				font-family: 'montserratbold';
				font-size: 18px;
				color: #FFF;

				margin-left: auto;

				span{
					margin-left: 8px;
				}

				&:focus{
					outline: none;
				}
			}
		}
	}
}
// DESKTOP
@include media-breakpoint-only(xl) {

}
// FIM DE DESKTOP



// NETBOOK PRA CIMA
@include media-breakpoint-up(lg) {
	.banner__controles{
		bottom: 200px;
	}
	.quemsomos__conteudo{
		background-image: url('../images/bg-quemsomos.jpg');
		background-position: center top;
		background-size: cover;
		background-repeat: no-repeat;
		color: #fff;
		padding: 40px 30px;
	}
	.contato__conteudo{
		margin-bottom: -400px;
	}
	.contato__conteudo{
		background-color: #03417d;
		color: #fff;
		padding: 90px 120px 70px 120px;
		text-align: center;
		background-image: url('../images/orcamento-bg-frase.png');
		background-size: 930px 127px;
		background-position: center 80px;
		background-repeat: no-repeat;
		z-index: 3;
		border-radius: 20px;
	}
	.clientes__conteudo{
		text-align: center;
		color: #144681;
		padding: 70px 0px;
		background-image: url('../images/clientes-bg-frase.png');
		background-repeat: no-repeat;
		background-size: 680px 99px;
		background-position: center 40px;
	}
	.locacao__conteudo{
		background-image: url('../images/locacao-bg-frase.png');
		background-repeat: no-repeat;
		background-size: 701px 155px;
		background-position: left 10px;
		padding: 65px 15px;
	}
	.noticias__conteudo{
		background-image: url('../images/noticias-bg-frase.png');
		background-repeat: no-repeat;
		background-size: 1062px 117px;
		background-position: center 30px;
		padding: 70px 15px;
	}
	.servicos{
		color: #151c48;
		text-align: center;
		padding: 70px 0px;
		background-image: url('../images/servicos-bg-frase.png');
		background-repeat: no-repeat;
		background-size: 723px 128px;
		background-position: center 40px;
	}
	.produtos__conteudo{
		background-image: url('../images/produtos-bg-frase.png');
		background-size: 799px 98px;
		background-repeat: no-repeat;
		background-position: center top;

		.titulo__secao{
			text-align: center;
		}
	}
}
// FIM DE NETBOOK PRA CIMA



// NETBOOK
@include media-breakpoint-only(lg) {

}
// FIM DE NETBOOK



// TABLET PRA BAIXO
@include media-breakpoint-down(md) {
	.banner__controles{
		bottom: 30px;
		font-size: 40px;
	}
	.quem-somos{
		background-image: url('../images/bg-quemsomos.jpg');
		background-position: center top;
		background-size: cover;
		background-repeat: no-repeat;
		color: #fff;
		padding: 40px 30px;
	}
	.quemsomos__txt{
		padding: 40px 0px;
	}
	.servicos{
		background-size: 100% auto;
	}
	.detalhes__img{
		justify-content: center;
		margin-bottom: 30px;
		margin-top: 20px;

		&:before{
			width: 50%;
			right: 40px;
			height: 110%;
			top: -5%;
		}
	}
	.detalhes__txt{
		text-align: center;
	}
	.baixar__flex{
		justify-content: center;
	}
	.video__thumbnail{
		margin-bottom: 40px;
	}
	.owl__noticias{
		position: relative;
		margin-bottom: 30px;

		.owl-nav{
			position: relative;
			bottom: 0;
			transform: translate(50%, 0%);
			right: 50%;
			display: flex;
			justify-content: center;
			margin-top: 30px;

			.owl-prev,
			.owl-next{
				color: #fff;
				text-shadow: 0px 0px 5px rgba(#000, 0.3);

				&:hover{
					color: #144681;
				}
			}
			.owl-prev{
				margin-right: 10px;
			}
		}
	}
	.contato{
		background-color: #151c48;
		color: #fff;
		padding: 90px 15px 70px 30px;
		text-align: center;
		background-image: url('../images/orcamento-bg-frase.png');
		background-size: 100% auto;
		background-position: center 80px;
		background-repeat: no-repeat;
		z-index: 3;
		border-radius: 10px 10px 0px 0px;
	}
	.clientes{
		text-align: center;
		color: #144681;
		padding: 70px 0px;
		background-image: url('../images/clientes-bg-frase.png');
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center 40px;
	}
	.locacao__conteudo{
		background-image: url('../images/locacao-bg-frase.png');
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: left 10px;
		padding: 65px 15px;
	}
	.noticias{
		background-image: url('../images/noticias-bg-frase.png');
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center 30px;
		padding: 70px 15px;
	}
	.servicos{
		color: #151c48;
		text-align: center;
		padding: 70px 0px;
		background-image: url('../images/servicos-bg-frase.png');
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center 40px;
	}
	.produtos{
		background-color: #03417d;

		.titulo__secao{
			text-align: center;
		}
	}
	.contato__botao{
		text-align: center;
	}
}
// FIM DE TABLET



// TABLET
@include media-breakpoint-only(md) {

}
// FIM DE TABLET



// MOBILE DEITADO
@include media-breakpoint-only(sm) {
	.baixar__flex{
		align-items: center;
		flex-direction: column;
	}
	.baixar__btn{
		&:first-child{
			margin-right: 0;
			margin-bottom: 20px;
		}
	}
}
// FIM DE MOBILE DEITADO



// MOBILE
@include media-breakpoint-down(xs) {
	.sub__secao{
		font-size: 30px;
	}
	.detalhes__img{
		&:before{
			display: none;
		}
	}
	.baixar__flex{
		align-items: center;
		flex-direction: column;
	}
	.baixar__btn{
		&:first-child{
			margin-right: 0;
			margin-bottom: 20px;
		}
	}
}
// FIM DE MOBILE
