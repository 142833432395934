.depoimentos#depoimento-mod-1{
	flex-grow:1;
	padding-bottom:60px;
	background-position: center top;

	.depoimento{
		background: #F6F6F7;
		border: 6px solid #fff;
		padding: 10px;
		text-align: center;
		margin:70px  10px 10px 10px;
		position: relative;
		padding-top:100px;
		box-shadow:0 0 8px rgba(#000,0.6);

		.avatar{
			width:140px;
			height:140px;
			border-radius: 100%;
			overflow:hidden;
			border:6px #FFF;
			position:absolute;
			top:-70px;
			left: calc(50% - 70px);
			border:6px solid #fff;
			box-shadow:0 0 8px rgba(#000,0.6);
		}
	}
}

#carouselDepoimento{
	.ctrls{
		text-align: center;
	}

	
	@include media-breakpoint-up(sm){
		.detalhe{
			position: absolute;
			top:30px;
			left:0;
			z-index:3;
		}

		.carousel-item{
			padding:0 50px;
		}
	}

	.controls{
		@extend .btn;
		
		margin: 10px;

		@include button-variant(#A80330, #A80330);
	}
}