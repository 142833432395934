@font-face {
    font-family: 'montserratblack';
    src: url('../fonts/montserrat-black/montserrat-black-webfont.eot');
    src: url('../fonts/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('../fonts/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratextrabold';
    src: url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.eot');
    src: url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.woff') format('woff'),
         url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.svg#montserratextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratextralight';
    src: url('../fonts/montserrat-extralight/montserrat-extra-light-webfont.eot');
    src: url('../fonts/montserrat-extralight/montserrat-extra-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-extralight/montserrat-extra-light-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-extralight/montserrat-extra-light-webfont.woff') format('woff'),
         url('../fonts/montserrat-extralight/montserrat-extra-light-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-extralight/montserrat-extra-light-webfont.svg#montserratextralight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratmediumitalic';
    src: url('../fonts/montserrat-medium-italic/montserrat-medium-italic-webfont.eot');
    src: url('../fonts/montserrat-medium-italic/montserrat-medium-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-medium-italic/montserrat-medium-italic-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-medium-italic/montserrat-medium-italic-webfont.woff') format('woff'),
         url('../fonts/montserrat-medium-italic/montserrat-medium-italic-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-medium-italic/montserrat-medium-italic-webfont.svg#montserratmedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/montserrat-regular/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat-regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratsemibold';
    src: url('../fonts/montserrat-semibold/montserrat-semibold-webfont.eot');
    src: url('../fonts/montserrat-semibold/montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.woff') format('woff'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.svg#montserratsemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratmedium';
    src: url('../fonts/montserrat-medium/montserrat-medium-webfont.eot');
    src: url('../fonts/montserrat-medium/montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.woff') format('woff'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.svg#montserratmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('../fonts/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratitalic';
    src: url('../fonts/montserrat-italic/montserrat-italic-webfont.eot');
    src: url('../fonts/montserrat-italic/montserrat-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-italic/montserrat-italic-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-italic/montserrat-italic-webfont.woff') format('woff'),
         url('../fonts/montserrat-italic/montserrat-italic-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-italic/montserrat-italic-webfont.svg#montserratitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}
