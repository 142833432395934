#produtos-mod-2{
	color: #777;

	.subtitle-produtos{
		font-weight: bold;
		font-size: 24px;
		color: #000;
		font-family: 'montserratbold';
		line-height: 28px;

		svg{
			color: #144681;
			margin:0;
			vertical-align: baseline;
			font-size: 20px;
		}
	}

	.row-produtos{
		& > *{
			display: flex;
		}
	}

	.produto{
		width: 100%;
		max-width: 350px;

		margin-left: auto;
		margin-right: auto;

		display: flex;
		flex-direction: column;

		.foto{
			margin-bottom: 0;
			position: relative;
		}

		.nome{
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			padding:10px;
			font-family: 'montserratbold';
			color: #144681;
		}

		.descricao{
			// flex-grow: 1;
			color: #777;
			padding-bottom:10px;
		}

		.btn{
			border-radius: 10px;
			font-family: 'montserratbold';
			padding: 10px 20px;
			background: transparent;
			border: 2px solid #144681;
			color: #144681;
			transition: all 0.3s linear;

			&:hover{
				background: #144681;
				color:#FFF;
			}
		}
	}

	.paginacao{

		& > *{
			$color: #144681;

			color: $color;


			&.active{
				background: $color;
				color: #fff;
				border-color: $color;
			}
		}
	}

	#carousel{
		max-width: 460px;
		margin-left:auto;
		margin-right:auto;

		figure{
			margin-bottom:0;
		}

		.controls{
			$bg: #144681;
			position:absolute;
			bottom:0;
			right:0;
			background: $bg;
			display: flex;
			font-size: 20px;

			&:before{
				content:'';
				width:25px;
				height:100%;
				position:absolute;
				right:calc(100% - 4px);
				top:0;
				background: $bg;
				clip-path: polygon(0 100%, 20px 0, 100% 0, 100% 100%);
			}

			a{
				display: block;
				padding:10px;
				color: #FFF;

				&:hover{
					color: #ededed;
					background: rgba(#000,.2);
					text-decoration: none;
				}
			}
		}
	}
}
