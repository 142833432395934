@import "_agenda-1.scss";
@import "_agenda-2.scss";
@import "_agenda-3.scss";


.calendario,
.calendario-filtro{
	width: 300px;
	max-width: 100%;
	text-align:center;
	padding:2px;
	margin-bottom: 40px;

	border-bottom: 5px solid #1C9647;

	$liWidth: (100% / 7);

	.calendario-head{
		background: #1C9647;
		color:#FFF;
		display: flex;
	}

	.ctrl-mes{
		display: block;
		border: none;
		color: #FFF;
		background: #1C9647;
		padding: 0.375rem 0.75rem;

		&:hover{
			background: darken(#1C9647, 10%);
			cursor: pointer;
		}

		&:focus{
			outline: none;
		}
	}

	.nome{
		padding: 0.375rem 0.75rem;
		flex-grow: 1;
	}

	.dias-semana{
		list-style: none;
		display: flex;
		padding-left:0;
		margin-bottom: 0;
		font-size: 12px;

		li{
			padding: 0.375em;
			width: $liWidth;
			border: 1px solid #FFF;
		}
	}

	.dias{
		padding-left: 0;
		display: flex;
		flex-wrap:wrap;
		list-style: none;
		margin-bottom: 0;
		font-size: 12px;
		li{
			width: $liWidth;
			padding: 0.375em;
			border: 1px solid #fff;
			margin-bottom: -1px;
			background: #EDEDED;

			@for $i from 0 through 6{

				&[data-dia="#{$i}"]{
					margin-left: ($liWidth * $i);
				}

			}

			&.active{
				background: darken(#EDEDED, 10%);
			}
		}
	}
}

.page-agenda{}