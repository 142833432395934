#clientes-e-parceiros-mod-1{

	.row.clientes{
		align-items: center;

		& > *{
			margin-bottom: 30px;
		}

		img{
			filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
		    filter: gray; /* IE6-9 */
		    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */

		    transition: all 0.3s linear;

		    &:hover{
		    	filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
				-webkit-filter: grayscale(0%);
		    }
		}
	}

	.paginacao{
		.active{
			background: #FFB554;
			color: #000;
		}
	}

	.titles{
		font-family: 'OFFISFB';

		&:before{
			content:'';
			display: inline-block;
			width:1em;
			height:0.2em;
			margin-bottom:0.2em;
			background: currentcolor;
			margin-right:0.5em;
		}
	}
}