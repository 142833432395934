
.select__custom__dropdown{
	.output{
		@extend .form-control;
		@extend .assistencia__input;

	    position: relative;

	    &:before{
	    	content: '';
	    	position: absolute;
	    	right: 20px;
	    	top: 50%;
	    	transform: translate(50%,-50%) rotate(var(--rotate, 0));
	    	border-left: 6px solid transparent;
	    	border-right: 6px solid transparent;
	    	border-top: 12px solid currentcolor;
	    	transition: all 0.3s linear;
	    	will-change: transform;
	    }
	}

	&.show{
		--rotate: 180deg;
	}

	.dropdown-menu{
		min-width: 180px;
		width: calc(100% - 50px);
		padding-top: 0;
		padding-bottom: 0;
		margin-left: 20px;
		color: #151c48;
		overflow: auto;
		max-height: 180px;

		li{
			padding: 10px;

			&.selected{
				background: $primary;
				color: #FFF;
			}

			&:not(.selected) {
				&:not(.disabled):hover{
					background: #eee;
				}
			}


			&:not(.disabled):hover{
				cursor: pointer;
			}

			&.disabled{
				color: #777;

				&:hover{
					cursor: default;
				}
			}
		}
	}
}

.custom-select-loaded{
	display: none;
}