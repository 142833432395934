#clientes-e-parceiros-mod-2{

	.row.clientes{
		align-items: center;
	}

	.paginacao{
		.active{
			background: #FFB554;
			color: #000;
		}
	}

	.cliente{
		a{
			&:hover{
				.nome{
					color: #144681
				}
			}
		}
	}

	.titles{
		font-family: 'montserratblack';

		&:before{
			content:'';
			display: inline-block;
			width:1em;
			height:0.2em;
			margin-bottom:0.2em;
			background: currentcolor;
			margin-right:0.5em;
		}
	}
}