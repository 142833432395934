.pesquisa__form{
	display: flex;
}
.pesquisa__input{
	color: #fff;
	border: 1px solid #fff;
	border-radius: 8px;
	background-color: #2b9cd8;
	padding: 10px 30px 8px 15px;
	height: auto;
	width: 100%;
	font-family: 'montserratmediumitalic';
	line-height: 18px;

	&:focus{
		box-shadow: none;
		outline: none;
	}

	&::placeholder{
		color: #fff;
	}
}
.pesquisa__btn{
	min-width: 46px;
	height: 46px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	border-radius: 8px;
	font-size: 24px;
	color: #2b9cd8;
	background-color: #FFF;
	z-index: 2;
	transform: translateX(-10px);

	&:focus{
		outline: none;
	}
}
.main__cima__whats,
.mobile__whats{
	display: flex;
	font-size: 28px;
	align-items: center;
	background-color: #03417d;
	padding: 10px 25px;
	border-radius: 8px;
	color: #fff;

	&:hover{
		text-decoration: none;
		color: #FFF;
	}
}
.whats__span{
	font-size: 15px;
	font-family: 'montserratsemibold';
	margin-left: 10px;
}
.main__cima__tel,
.mobile__tel{
	display: flex;
	color: #fff;
	align-items: center;
}
.tel__span{
	margin-left: 10px;
	font-family: 'montserratsemibold';
}
.main__cima__logo{
	display: block;
}
.main__menu{

	@include media-breakpoint-down(md){
		background-color: #2b9cd8;
	}

	@include media-breakpoint-up(md){
		background-color: #03417d;
	}
}
.main__menu__nav{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}
.main__menu__link{
	color: #fff;
	font-family: 'montserratsemibold';
	font-size: 15px;
}
.main__dropmenu__btn{
	background-color: transparent;
	padding: 0;
	padding-right: 10px;
	border: none;
	display: flex;
	align-items: center;
	margin-left: 5px;

	&:focus{
		outline: none;
	}
}
.dropmenu{
	font-family: 'montserratsemibold';
	color: #03417d;
	border-bottom: 4px solid #03417d;
	background-color: #fff;
	font-size: 13px;
	list-style: none;
}
.submenu{
	list-style: none;
	margin-bottom: 0;
	padding: 10px 0px;
	font-family: 'montserratsemibold';
	color: #03417d;
	border-bottom: 4px solid #03417d;
	background-color: #ededed;
}
.main__menu__item--active{
	background-color: #144681;
}
.menu__backdrop{
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(#000, 0.8);
	z-index: 999;
	display: none;
	position: fixed;
}
// DESKTOP
@include media-breakpoint-only(xl) {
	.mobile__controls,
	.mobile__contato,
	.pesquisa__mbl{
		display: none;
	}
	.main__cima{
		background-color: #2b9cd8;
		padding: 10px 0px;
	}
	.main__cima__segura{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.pesquisa__form{
		width: 100%;
		max-width: 360px;
	}
	.main__menu{
		color: #000;
		border-top: 1px solid #172350;
		border-bottom: 1px solid #172350;
	}
	.main__menu__nav{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.main__dropmenu__ctrl,
	.submenu__ctrl{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.main__menu__item{
		position: relative;

		&:hover{
			background-color: #144681;

			.main__dropmenu__ctrl{
				&:after{
					opacity: 1;
				}
			}

			.dropmenu{
				display: block;
				z-index: 100;
			}
		}
	}
	.main__menu__link{
		display: flex;
		padding: 10px;
		cursor: pointer;

		&:hover{
			text-decoration: none;
			color: #fff;
		}
	}
	.main__dropmenu__ctrl{
		position: relative;
		z-index: 101;

		&:after{
			width: 16px;
			height: 16px;
			content: '';
			background-color: #151c48;
			border-radius: 50%;
			left: 20px;
			bottom: -8px;
			position: absolute;
			z-index: 10;
			opacity: 0;
			transition: all 0.2s linear;
		}
	}
	.dropmenu{
		position: absolute;
		left: 0;
		top: calc(100%);
		min-width: 270px;
		list-style: none;
		padding: 12px 0px;
		margin-bottom: 0;
	}
	.submenu__btn{
		width: 16px;
		height: 16px;
		font-size: 9px;
		display: flex;
		background-color: transparent;
		justify-content: center;
		align-items: center;
		border: 1px solid #151c48;
		border-radius: 50%;
		margin-right: 10px;

		&:focus{
			outline: none;
		}
	}
	.dropmenu__item{
		cursor: pointer;
		position: relative;

		&:hover{
			background-color: #ededed;

			.submenu{
				display: block;
				z-index: 1001;
			}
		}
	}
	.subcategoria__item{
		&:hover{
			background-color: #fff;
		}
	}
	.submenu__link,
	.subcategoria__link{
		display: block;
		padding: 7px 20px 5px 20px;

		&:hover{
			text-decoration: none;
			cursor: pointer;
		}
	}
	.submenu{
		position: absolute;
		top: 0;
		left: 100%;
		min-width: 192px;
	}
}
// FIM DE DESKTOP



// NETBOOK PRA CIMA
@include media-breakpoint-up(lg) {

}
// FIM DE NETBOOK PRA CIMA



// NETBOOK
@include media-breakpoint-only(lg) {

}
// FIM DE NETBOOK



// NETBOOK PRA BAIXO
@include media-breakpoint-down(lg) {
	.mobile__controls{
		background-color: #2b9cd8;
		color: #fff;
		display: flex;
		justify-content: space-around;
		padding: 10px 0px;
	}
	.mobile__controls__btn,
	.mobile__controls__search{
		color: #fff;
		font-size: 26px;

		&:focus{
			outline: none;
			box-shadow: none;
		}

		&:hover{
			color: #fff;
		}
	}
	.pesquisa__mbl{
		display: flex;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		z-index: 1000;
		position: fixed;
		top: -200px;
		left: 5px;
		transition: all 0.5s linear;
	}
	.pesquisa__mbl--shown{
		top: 100px;
	}

	.main__menu__segura{
		padding: 0px;
	}
	.main__topo{
		background-color: #2b9cd8;
		position: fixed;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		top: 0;
		left: -250px;
		z-index: 1000;
		transition: all 0.4s linear;
	}
	.main__topo--shown{
		left: 0;
	}
	.main__menu__nav{
		margin-bottom: 20px;
	}
	.main__cima__logo{
		margin-bottom: 20px;
		padding: 10px;
		text-align: center;
	}
	.main__menu__item{
		&:hover{
			background-color: #144681;
		}
	}
	.main__menu__link{
		display: block;
		padding: 10px;

		&:hover{
			color: #fff;
			text-decoration: none;
		}
	}
	.main__cima__whats,
	.main__cima__tel,
	.pesquisa__form{
		display: none;
	}
	.mobile__whats{
		padding: 10px 0px;
	}
	.main__dropmenu__ctrl{
		display: flex;
		justify-content: space-between;
	}
	.dropmenu{
		padding-left: 0px;
	}
	.submenu__ctrl{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.submenu__btn{
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: transparent;
		border: 1px solid #151c48;
		border-radius: 50%;

		&:focus{
			outline: none;
		}
	}
	.dropmenu__item{
		&:hover{
			background-color: #ededed;
		}
	}
	.subcategoria__link,
	.submenu__link{
		display: block;
		padding: 10px;

		&:hover{
			text-decoration: none;
		}
	}
	.subcategoria__link{
		&:hover{
			background-color: #fff;
		}
	}
	.mobile__whats{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;

		&:hover{
			color: #fff;
			text-decoration: none;
		}
	}
	.mobile__tel{
		display: flex;
		justify-content: center;

		&:hover{
			color: #fff;
			text-decoration: none;
		}
	}
}
// FIM DE NOTEBOOK

// TABLET PRA BAIXO
@include media-breakpoint-down(md) {

}
// FIM DE TABLET PRA BAIXO

// TABLET
@include media-breakpoint-only(md) {

}
// FIM DE TABLET



// MOBILE DEITADO
@include media-breakpoint-only(sm) {

}
// FIM DE MOBILE DEITADO



// MOBILE
@include media-breakpoint-down(xs) {

}
// FIM DE MOBILE
