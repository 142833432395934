@import "_modelo-1.scss";
@import "_modelo-2.scss";
@import "_modelo-3.scss";

.modal-depoimento{
	color: #777;

	.autor{
		padding-bottom:15px;
		margin-bottom:30px;
		border-bottom:10px solid rgba(#000,0.1);
		color:#000;
	}

	.modal-body{
		padding:10px;
	}
}