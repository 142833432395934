.galeria-fotos#modelo-2{
	.galerias{
		display: flex;
		flex-wrap:wrap;
	}

	.galeria{
		background:#FFF;

		.descricao{
			padding: 15px;
			background:#FFF;
			display: flex;
			align-items:center;
			justify-content:center;
			text-align: center;
			border:1px solid rgba(#000,0.2);
			position: relative;

			&:before{
				content: '';
				display: block;
				position: absolute;
				border:15px solid transparent;
			}
		}

		.nome{
			font-size: 18px;
			line-height: 1.3;
			max-height: calc(1em * 1.3 * 2);
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp:2;
			font-weight: bold;
			margin-bottom: 10px;
		}

		.publicacao{
			color: #777;
		}

		.btn{
			border-bottom-width: 3px;
			@include button-variant(#932025,#932025);
			border-color: rgba(#000,0.2);
			min-width: 120px;
		}
		
		.divider{
			border:1px solid #ccc;
			position:relative;
			margin:20px 0;

			&:before{
				content:'';
				position: absolute;
				width:10px;
				height:10px;
				background:#CCC;
				transform:rotate(45deg) translateY(-50%);
			}
		}

		@include media-breakpoint-between(sm, md) {
			display: flex;
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;

			& > *{
				width: 50%;
			}

			.descricao:before{
				top:50%;
				transform:translateY(-50%);
			}

			&:nth-child(odd){
				.descricao:before{
					right:100%;
					border-right-color:#FFF;
				}
			}

			&:nth-child(even){
				.descricao:before{
					left:100%;
					border-left-color:#FFF;
				}

				.foto{
					order:2;
				}

				.descricao{
					order:1;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			width:50%;
			display: flex;

			& > *{
				width: 50%;
			}

			.descricao:before{
				right:100%;
				border-right-color:#FFF;
			}

			&:nth-child(n+3) {
				.foto{
					order: 2;
				}
				.descricao{
					order: 1;

					&:before{
						border-left-color:#FFF;
						border-right-color:transparent;
						right: auto;
						left:100%;
					}
				}
			}

			&:nth-child(n+5) {
				.foto{
					order: 1;
				}
				.descricao{
					order: 2;
				}
			}

			&:nth-child(n+7) {
				.foto{
					order: 2;
				}
				.descricao{
					order: 1;
					

					&:before{
						border-left-color:#FFF;
						border-right-color:transparent;
						right: auto;
						left:100%;
					}
				}
			}

			&:nth-child(n+9) {
				.foto{
					order: 1;
				}
				.descricao{
					order: 2;
				}
			}
		}
	}

	.paginacao{
		margin-top: 40px;
	}
}